@font-face {
  font-family: "RobotoBold";
  src: url("../../@assets/Roboto/Roboto-Bold.ttf");
}

@font-face {
  font-family: "RobotoRegular";
  src: url("../../@assets/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "RobotoMedium";
  src: url("../../@assets/Roboto/Roboto-Medium.ttf");
}

@font-face {
  font-family: "RobotoLight";
  src: url("../../@assets/Roboto/Roboto-Light.ttf");
}

.describeHtml p {
  color: #424b5a;
  font-family: "RobotoRegular";
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.5rem;
}
.describeHtml h1 {
  color: #424b5a;
  font-family: "RobotoBold";
  font-size: 0.875rem;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;
}

.describeHtml div {
  color: #424b5a;
  font-family: "RobotoRegular";
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.describeHtml img {
  width: 80% !important;
  height: auto !important;
}

@media (min-width: 0px) and (max-width: 1000px) {
  /* CSS */

  .describeHtml img {
    width: 70% !important;
    height: auto !important;
  }
}
