@font-face {
  font-family: "RobotoBold";
  src: url("../../@assets/Roboto/Roboto-Bold.ttf");
}

@font-face {
  font-family: "RobotoRegular";
  src: url("../../@assets/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "RobotoMedium";
  src: url("../../@assets/Roboto/Roboto-Medium.ttf");
}

@font-face {
  font-family: "RobotoLight";
  src: url("../../@assets/Roboto/Roboto-Light.ttf");
}
.customTextMedium {
  color: #424b5a;
  font-family: "RobotoMedium";
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: -0.47px;
  line-height: 16px;
}

.customTextBold {
  color: #424b5a;
  font-family: "RobotoBold";
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1rem;
}

.customTextRegular {
  color: #424b5a;
  font-family: "RobotoRegular";
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}

.SemiBoldText {
  color: #002439;
  font-family: "RobotoMedium";
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: -0.029375rem;
  line-height: 1rem;
}
