@font-face {
  font-family: "RobotoBold";
  src: url("../../@assets/Roboto/Roboto-Bold.ttf");
}

@font-face {
  font-family: "RobotoRegular";
  src: url("../../@assets/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "RobotoMedium";
  src: url("../../@assets/Roboto/Roboto-Medium.ttf");
}

@font-face {
  font-family: "RobotoLight";
  src: url("../../@assets/Roboto/Roboto-Light.ttf");
}
.systemFilterView {
  padding-top: 11px;
}

.customListGroup {
  border-radius: 0px !important;
  border-color: white !important;
}

.checkBoxDetails {
  padding: 0.5rem 0.5rem 0.5rem 0rem !important;
  width: 1.775rem;
}

.scrollRow {
  max-height: 170.625rem;
  /* height: 35.4375rem; */
  overflow: auto;
  overflow-x: auto;
}

.chooseLangBtnNew {
  padding-right: 5;
}
