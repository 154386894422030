@font-face {
  font-family: "RobotoBold";
  src: url("../../@assets/Roboto/Roboto-Bold.ttf");
}

@font-face {
  font-family: "RobotoRegular";
  src: url("../../@assets/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "RobotoMedium";
  src: url("../../@assets/Roboto/Roboto-Medium.ttf");
}

@font-face {
  font-family: "RobotoLight";
  src: url("../../@assets/Roboto/Roboto-Light.ttf");
}
.systemFilterView {
  padding-top: 11px;
}

#custom-search-form {
  margin: 0;
  margin-top: 5px;
  padding: 0;
}

#custom-search-form .search-query {
  padding-right: 3px;
  padding-right: 4px \9;
  padding-left: 3px;
  padding-left: 4px \9;
  /* IE7-8 doesn't have border-radius, so don't indent the padding */

  margin-bottom: 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  width: 16vw !important;
  height: 27px;
  border-color: #abb2bf;
  border-width: 1.5px;
}

#custom-search-form button {
  border: 0;
  background: none;
  /** belows styles are working good */
  padding: 2px 5px;
  margin-top: 0px;
  position: relative;
  /* IE7-8 doesn't have border-radius, so don't indent the padding */
  margin-bottom: 5px;
  margin-left: -25px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.search-query:focus + button {
  z-index: 3;
}

input::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 16;
  font-family: "RobotoRegular";
  color: #a1aeb7;
  letter-spacing: -0.47px;
}

.SystemFilterSpan92 {
  margin-top: 2px;
  padding-right: 30px;
  padding-top: 2px;
  height: 50px;
}

.nullstill-filtere {
  color: #424b5a;
  font-family: "RobotoRegular";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 0.875rem;
  cursor: pointer;
  float: right;
}

.row2PaddingRight {
  padding-right: 0.625rem;
  width: 45%;
  height: 1.875rem;
  float: right;
}

.row2PaddingLeft {
  padding-bottom: 3.25rem;
  /* padding-top: 1.25rem; */
  padding-left: 0.225rem;
  width: 45%;
  height: 1.875rem;
  float: left;
}

.showMoreButton {
  color: #a1aeb7 !important;
  font-family: "RobotoRegular" !important;
  font-size: 0.75rem !important;
  letter-spacing: -0.025rem !important;
  line-height: 0.875rem !important;
  background-color: #ffffff;
  border-color: #ffffff;
}

.ExtraDisplay {
  display: block !important;
}
