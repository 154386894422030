@font-face {
  font-family: "RobotoBold";
  src: url("../../@assets/Roboto/Roboto-Bold.ttf");
}

@font-face {
  font-family: "RobotoRegular";
  src: url("../../@assets/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "RobotoMedium";
  src: url("../../@assets/Roboto/Roboto-Medium.ttf");
}

@font-face {
  font-family: "RobotoLight";
  src: url("../../@assets/Roboto/Roboto-Light.ttf");
}

@media only screen and (max-width: 690px) {
  .languageBar {
    height: 4.748125rem;
  }
  .chooseLang {
    width: 1.4rem;
  }
  .chooseLangBtn {
    font-size: 0.625rem !important;
    line-height: 0.75rem !important;
    font: "RobotoRegular" !important;
    color: #424b5a !important;
    height: 1.875rem !important;
    width: 1.0625rem !important;
    text-align: center !important;
    padding-left: 0px !important;
  }

  .selectedChooseLangBtn {
    color: #000 !important;
    font-weight: bold !important;
  }

  .chooseLangBtnDisable {
    font-size: 0.625rem !important;
    line-height: 0.75rem !important;
    font: "RobotoRegular" !important;
    color: #424b5a !important;
    height: 1.875rem !important;
    width: 1.0625rem !important;
    text-align: center !important;
    padding-left: 6px !important;
  }
}

.languageBar {
  height: 4.748125rem;
}
.chooseLang {
  width: 1.8rem;
}
.chooseLangBtn {
  font-size: 0.875rem !important;
  line-height: 0.75rem !important;
  font: "RobotoRegular" !important;
  color: #424b5a !important;
  height: 1.875rem !important;
  width: 1.3rem !important;
  text-align: center !important;
  padding-left: 0px !important;
}

.chooseLangBtnDisable {
  font-size: 0.875rem !important;
  line-height: 0.75rem !important;
  font: "RobotoRegular" !important;
  color: #424b5a !important;
  height: 1.875rem !important;
  width: 1.2625rem !important;
  text-align: center !important;
  padding-left: 6px !important;
}
