@font-face {
  font-family: "RobotoBold";
  src: url("../../@assets/Roboto/Roboto-Bold.ttf");
}

@font-face {
  font-family: "RobotoRegular";
  src: url("../../@assets/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "RobotoMedium";
  src: url("../../@assets/Roboto/Roboto-Medium.ttf");
}

@font-face {
  font-family: "RobotoLight";
  src: url("../../@assets/Roboto/Roboto-Light.ttf");
}

.checkBoxWithContainer {
  margin-top: 10px;
  padding-left: 0.625rem;
}
