@font-face {
  font-family: "RobotoBold";
  src: url("../../@assets/Roboto/Roboto-Bold.ttf");
}

@font-face {
  font-family: "RobotoRegular";
  src: url("../../@assets/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "RobotoMedium";
  src: url("../../@assets/Roboto/Roboto-Medium.ttf");
}

@font-face {
  font-family: "RobotoLight";
  src: url("../../@assets/Roboto/Roboto-Light.ttf");
}
.customLi {
  padding-left: 0.325rem !important;
  padding-top: 0.1125rem !important;
  padding-bottom: 0.3125rem !important;
  border-color: #e5e9eb !important;
  background-color: #e5e9eb !important;
}

.customSemiHeaderPaddingleft {
  padding-left: 0rem !important;
  padding-top: 0rem !important;
}

.ShowSmallDeviceSemiHeaderMenu {
  display: none !important;
}

@media (min-width: 0px) and (max-width: 725px) {
  /* CSS */

  .hideSmallDeviceSemiHeaderMenu {
    display: none !important;
  }

  .ShowSmallDeviceSemiHeaderMenu {
    display: block !important;
  }

  .smallDevicePaddingBottomSemiHeader {
    padding-bottom: 0.212rem !important;
  }
}
