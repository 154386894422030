@font-face {
  font-family: "RobotoBold";
  src: url("../../@assets/Roboto/Roboto-Bold.ttf");
}

@font-face {
  font-family: "RobotoRegular";
  src: url("../../@assets/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "RobotoMedium";
  src: url("../../@assets/Roboto/Roboto-Medium.ttf");
}

@font-face {
  font-family: "RobotoLight";
  src: url("../../@assets/Roboto/Roboto-Light.ttf");
}

.mainHeaderText {
  font-family: "RobotoBold";
  color: #002439 !important;
  font-size: 2.75em !important; /* 2.67vw */
  font-weight: bold !important;
  letter-spacing: -1.47px;
  line-height: 52px;
}

.semiMainHeaderText {
  color: #002439;
  font-family: "RobotoRegular";
  font-size: 1.3125rem;
  letter-spacing: -0.7px;
  line-height: 24px;
}

.headerCustomConatiner {
  background-color: #e5e9eb;
}

.headerCustomConatinerWithPadding {
  padding-top: 3.925rem !important;
  padding-bottom: 3.925rem !important;
  background-color: #e5e9eb;
  background-image: url("../../@assets/Images/bg-pattern.png");
  background-size: 396px 218px;
  background-repeat: no-repeat;
  background-position-x: 75%;
}

.headerBackMenu {
  padding-top: 0.625rem !important;
  padding-bottom: 0rem !important;
  padding-left: 0px !important;
}

@media (min-width: 0px) and (max-width: 550px) {
  .mainHeaderText {
    color: #002439 !important;
    font-family: "RobotoBold" !important;
    font-size: 1.25rem !important;
    font-weight: bold !important;
    letter-spacing: -0.033125rem !important;
    line-height: 1.1875rem !important;
  }

  .semiMainHeaderText {
    color: #002439;
    font-family: "RobotoRegular";
    font-size: 0.825rem;
    letter-spacing: -0.020625rem;
    line-height: 0.6875rem;
  }
}

@media (min-width: 0px) and (max-width: 725px) {
  /* CSS */
}
