@font-face {
  font-family: "RobotoBold";
  src: url("../../@assets/Roboto/Roboto-Bold.ttf");
}

@font-face {
  font-family: "RobotoRegular";
  src: url("../../@assets/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "RobotoMedium";
  src: url("../../@assets/Roboto/Roboto-Medium.ttf");
}

@font-face {
  font-family: "RobotoLight";
  src: url("../../@assets/Roboto/Roboto-Light.ttf");
}

.barStyleVertical {
  height: 20rem !important;
}

.barStyleHorizontal {
  height: 10.9375rem;
}

.barStyleHorizontalEquipments {
  height: 32.9375rem;
}

.barStyleHorizontalCauses {
  height: 22.9375rem;
}
